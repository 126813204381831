import './Room1.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from './framework/ThreeRenderService'
import CombinationLock from './framework/CombinationLock';
import { getDataService } from './framework/DataService';
import BookDialog from './framework/BookDialog';
import Notifications from './framework/Notifications';
import Timer from './framework/Timer';
import * as THREE from 'three';
import TableView from './framework/TableView';
import PlayerRepresentation from './framework/PlayerRepresentation';
import { getTranslationService } from './TranslationService';
import InspectThreeDialog from './framework/InspectThreeDialog';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import MenuOverlay from './framework/MenuOverlay';
import FinalDialog from './framework/FinalDialog';

class Room1 extends Component {
  constructor(props) {
    super(props);
    getRenderService();
    this.mounted = false;

    this.state = { mounted: false, selectedObject: undefined, playerInitialized: false, pointerLock: false };

    this.updateWorldState = this.updateWorldState.bind(this);
    this.tickScene = this.tickScene.bind(this);

    this.interactions = {
      "number_lock_dungeon_1": "combinationlock",
      "number_lock_dungeon_2": "combinationlock",
      "number_lock_door_1": "combinationlock",
      "number_lock_door_2": "combinationlock",
      "number_lock_chest": "combinationlock",
      "WallBrick_1": "buttonsequence",
      "WallBrick_2": "buttonsequence",
      "WallBrick_3": "buttonsequence",
      "WallBrick_4": "buttonsequence",
      "WallBrick_5": "buttonsequence",
      "Button_1_1": "buttonsequence",
      "Button_2_1": "buttonsequence",
      "Button_3_1": "buttonsequence",
      "Button_4_1": "buttonsequence",
      "Button_1_2": "buttonsequence",
      "Button_2_2": "buttonsequence",
      "Button_3_2": "buttonsequence",
      "Button_4_2": "buttonsequence",
      "Book_Small_Green001": "book",
      "Book_Small_DeepRed001": "book",
      "Book_Big_Green": "book",
      "Book_Big_DeepRed001": "book",
      "Book_Big_DeepRed002": "book",
      "Book_Big_DeepRed": "book",
      "Book_Big_Brown": "book",
      "Book_Big_Red": "book",
      "Book_Small_Green": "book",
      "Book_Big_Green001": "book",
      "Book_Small_DeepRed": "book",
      "Book_Red": "book",
      "Book_Big_Red001": "book",
      "Book_Green": "book",
      "Key_1_1": "pickup",
      "Key_1_2": "pickup",
      "Key_2_1": "pickup",
      "Key_2_2": "pickup",
      "Key_3_1": "pickup",
      "Key_3_2": "pickup",
      "Puzzle_1": "pickup",
      "Puzzle_2": "pickup",
      "Puzzle_3": "pickup",
      "Puzzle_4": "pickup",
      "Puzzle_5": "pickup",
      "Puzzle_6": "pickup",
      "Amphora": "inspect",
      "Amphora001": "inspect",
      "Amphora003": "inspect",
      "Candelier_Small": "inspect",
      "Candelier_Small": "inspect",
      "Barrel_Closed": "inspect",
      "Plate": "inspect",
      "Chair": "inspect",
      "Inkwell": "inspect",
      "Quill": "inspect",
      "Scroll_Chest": "inspect",
      "Cauldron_Small": "inspect",
      "Jar_Full_004": "inspect",
      "Jar_Full_001": "inspect",
      "Jar_Full_002": "inspect",
      "Jar_Full_003": "inspect",
      "Table_Small": "view"
    };
  }

  componentDidMount() {
    if (this.mounted) {
      return;
    }
    this.mounted = true;

    const self = this;
    console.log("Component did mount.");
    if (!this.state.mounted) {
      this.setState({ mounted: true });

      const loader = new GLTFLoader().setPath('/');

      loader.load('test_dungeon_scene.glb', (gltf) => {

        getRenderService().scene.add(gltf.scene);

        gltf.scene.traverse(child => {
          if (child.type == "PointLight") {
            child.intensity /= 400;
          }
          if (child.name === "Chest_Open") {
            child.visible = false;
          }
          if (child.name.indexOf("Button_Lamp") >= 0) {
            child.visible = false;
          }
          if (child.name === "Scroll_Chest") {
            child.visible = false;
          }
          if (child.isMesh) {
            if (child.material) {
              child.material.roughness = 1;
              child.material.metalness = 0;
            }
          }

        });

        getRenderService().worldOctree.fromGraphNode(gltf.scene);
        getRenderService().animate();

        getRenderService().triggerCallback("sceneupdate", getRenderService().scene);

      });

      getRenderService().addCallback("hover", (intersects, rs) => {
        if (intersects.length > 0) {
          let intersect = intersects[0];
          for (let i = 0; i < intersects.length; i++) {
            if (intersects[i].object.visible) {
              intersect = intersects[i];
              break;
            }
          }

          const name = intersect.object.name;
          if (intersect.distance > 3 || name.indexOf("Dungeon") >= 0 || name.indexOf("Basement") >= 0 || !intersect.object.visible) {
            if (!this.state.cameraFocus) {
              rs.outlinePass.selectedObjects = [];
            }
            self.setState({ selectedObject: undefined });
            return;
          }
          if (!this.state.cameraFocus) {
            rs.outlinePass.selectedObjects = [intersects[0].object];
          }
          if (!this.state.selectedObject || intersect.object.name != this.state.selectedObject.object.name) {
            self.setState({ selectedObject: intersect });
          }
        }
      });

      getRenderService().addCallback("mousedown", (intersects, rs) => {
        if (!this.state.selectedObject || this.state.cameraFocus) {
          return;
        }
        const name = this.state.selectedObject.object.name;
        let currentInteraction = self.interactions[name];
        if (currentInteraction) {
          if (currentInteraction == "combinationlock") {
            this.setState({ combinationlock: true });
            document.exitPointerLock();
          }
          if (currentInteraction == "book") {
            this.setState({ book: true });
            document.exitPointerLock();
          }
          if (currentInteraction == "inspect") {
            this.setState({ inspect: name });
            document.exitPointerLock();
            getRenderService().enableKeyboardControls = false;
          }
          if (currentInteraction == "buttonsequence" && name.indexOf("Wall") >= 0) {
            getDataService().triggerGameEvent({ "event": "sequence_button", "sequence": "wall", "key": name });
          }
          if (currentInteraction == "buttonsequence" && name.indexOf("Button") >= 0) {
            getDataService().triggerGameEvent({ "event": "sequence_button", "sequence": "button", "key": name.substring(0, name.length - 2) });
          }
          if (currentInteraction == "pickup") {
            getDataService().triggerGameEvent({ "event": "key_pickup", "key": name });
            let tableSpawner = rs.scene.getObjectByName("Table_Spawner");
            this.state.selectedObject.position.copy(tableSpawner.position);
            this.state.selectedObject.position.x += Math.random() - 0.5;
            this.state.selectedObject.rotation.set(0, 0, 0);

            if (name.indexOf("Puzzle") >= 0) {
              this.state.selectedObject.position.x += Math.random() - 0.5;
            }
            this.state.selectedObject.name = name + "_Movable";
            //triggerEvent(name);
          }
        }
        if (name == "Table_Small") {
          let tableCamera = rs.scene.getObjectByName("Camera").children[0];
          rs.freeCamera = true;
          let tarPos = new THREE.Vector3();
          tableCamera.getWorldPosition(tarPos);

          let tarQuat = new THREE.Quaternion(-0.0111, 0.8049, 0.5930, 0.0151);
          tableCamera.getWorldQuaternion(tarQuat);

          document.exitPointerLock();
          rs.enablePointerLock = false;
          this.setState({ cameraFocus: { position: tarPos, quaternion: tarQuat } });
        }
      });


      getRenderService().addCallback("scenetick", (timediff, rs) => {
        const users = getDataService().getUsers();

        if (this.state.pointerLock != (document.pointerLockElement === document.body)) {
          this.setState({ pointerLock: (document.pointerLockElement === document.body) });
        }

        if (self.state.cameraFocus) {
          var camPos = rs.camera.position.clone();       // Holds current camera position
          var targetPos = this.state.cameraFocus.position.clone();// Target position

          // Interpolate camPos toward targetPos
          rs.camera.position.lerp(targetPos, 3 * timediff);
          rs.camera.quaternion.slerp(this.state.cameraFocus.quaternion, 3 * timediff);
        }

        let playerNamesUI = "";

        for (const uId in users) {
          const u = users[uId];
          if (u._data.isMe) {
            if (!self.state.playerInitialized) {
              if (u._data.state.playerNumber !== undefined) {
                const playerNumber = getDataService().getPlayerNumber(u._data.id);
                const spawner = rs.scene.getObjectByName("PlayerSpawner" + ((playerNumber% 2) + 1));
                rs.playerCollider.start.set(0, 0.35, 0);
                rs.playerCollider.end.set(0, 1.7, 0);
                rs.playerCollider.radius = 0.35;
                rs.playerCollider.translate(spawner.position);
                rs.camera.position.copy(rs.playerCollider.end);
                rs.camera.rotation.set(0, 0, 0);
                self.setState({ playerInitialized: true });
              }
            }
            continue;
          }
        }
      });

      getRenderService().addCallback("sceneupdate", (scene, rs) => {
        self.updateWorldState(getDataService().getGameState(), getDataService());
      });
      getDataService().addCallback("gamestate", self.updateWorldState);
    }
  }

  updateWorldState(gameState, ds) {
    this.setState({gamestate: gameState});

    let recalculate = false;

    let sequence_fit = 0;
    let tar_seq = "25431";
    if (gameState.wall) {
      for (let i = 0; i < gameState.wall.length; i++) {
        if (gameState.wall.charCodeAt(i) == tar_seq.charCodeAt(sequence_fit)) {
          sequence_fit++;
          if (sequence_fit == tar_seq.length) {
            break;
          }
        } else {
          sequence_fit = 0;
          if (gameState.wall.charCodeAt(i) == tar_seq.charCodeAt(sequence_fit)) {
            sequence_fit = 1;
          }
        }
      }
    }

    if (this.state.wall_seq_fit != sequence_fit) {
      this.setState({ wall_seq_fit: sequence_fit });
    }

    let button_sequence_fit = 0;
    let button_tar_seq = "14123";
    if (gameState.button) {
      for (let i = 0; i < gameState.button.length; i++) {
        if (gameState.button.charCodeAt(i) == button_tar_seq.charCodeAt(button_sequence_fit)) {
          button_sequence_fit++;
          if (button_sequence_fit == button_tar_seq.length) {
            break;
          }
        } else {
          button_sequence_fit = 0;
          if (gameState.button.charCodeAt(i) == button_tar_seq.charCodeAt(button_sequence_fit)) {
            button_sequence_fit = 1;
          }
        }
      }
    }

    if (this.state.wall_seq_fit != sequence_fit) {
      this.setState({ wall_seq_fit: sequence_fit });
    }

    if (this.state.button_seq_fit != button_sequence_fit) {
      this.setState({ button_seq_fit: button_sequence_fit });
    }

    if (this.state.numberOfHints != gameState.numberOfHints) {
      this.setState({ numberOfHints: gameState.numberOfHints });
    }

    getRenderService().scene.traverse((element) => {

      if (element.name && element.name == "Dungeon_Door_1") {
        const tarRot = -10.0 / 180 * Math.PI;
        if (gameState.lock1 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          recalculate = true;
        }
      }
      if (element.name && element.name.indexOf("Code_Lamp_1") >= 0) {
        element.rotation.y = sequence_fit >= 1 ? Math.PI : 0;
      }
      if (element.name && element.name.indexOf("Code_Lamp_2") >= 0) {
        element.rotation.y = sequence_fit >= 2 ? Math.PI : 0;
      }
      if (element.name && element.name.indexOf("Code_Lamp_3") >= 0) {
        element.rotation.y = sequence_fit >= 3 ? Math.PI : 0;
      }
      if (element.name && element.name.indexOf("Code_Lamp_4") >= 0) {
        element.rotation.y = sequence_fit >= 4 ? Math.PI : 0;
      }
      if (element.name && element.name.indexOf("Code_Lamp_5") >= 0) {
        element.rotation.y = sequence_fit >= 5 ? Math.PI : 0;
      }
      if (element.name && element.name.indexOf("Button_Lamp1") >= 0) {
        element.visible = button_sequence_fit >= 1;
      }
      if (element.name && element.name.indexOf("Button_Lamp2") >= 0) {
        element.visible = button_sequence_fit >= 2;
      }
      if (element.name && element.name.indexOf("Button_Lamp3") >= 0) {
        element.visible = button_sequence_fit >= 3;
      }
      if (element.name && element.name.indexOf("Button_Lamp4") >= 0) {
        element.visible = button_sequence_fit >= 4;
      }
      if (element.name && element.name.indexOf("Button_Lamp5") >= 0) {
        element.visible = button_sequence_fit >= 5;
      }
      if (gameState.lock1 && element.name && element.name.indexOf("number_lock_dungeon_1") >= 0) {
        element.visible = false;
      }
      if (element.name && element.name == "Dungeon_Door_2") {
        const tarRot = -200.0 / 180.0 * Math.PI;
        if (gameState.lock2 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          recalculate = true;
        }
      }
      if (gameState.lock2 && element.name && element.name.indexOf("number_lock_dungeon_2") >= 0) {
        element.visible = false;
      }
      if (element.name && element.name == "Dungeon_Door_3") {
        const tarRot = -90.0 / 180.0 * Math.PI;
        if (gameState.lock1 && gameState.lock2 && element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          recalculate = true;
        }
      }
      if (gameState.lock3 && element.name) {

        if (element.name === "Chest_Open" && !element.visible) {
          element.visible = true;
          recalculate = true;
        }
        if (element.name === "Scroll_Chest" && !element.visible) {
          element.visible = true;
        }
        if (element.name === "Chest_Closed") {
          element.visible = false;
        }
        if (element.name.indexOf("number_lock_chest") >= 0) {
          element.visible = false;
        }
      }
      if (gameState.lock4 && element.name && element.name == "number_lock_door_1") {
        element.visible = false;
      }
      if (gameState.lock5 && element.name && element.name.indexOf("number_lock_door_2") >= 0) {
        element.visible = false;
      }
      if (gameState.lock4 && gameState.lock5 && sequence_fit >= tar_seq.length && element.name && element.name.indexOf("Final_Door") >= 0) {
        const tarRot = 180.0 / 180.0 * Math.PI;
        if (element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          recalculate = true;
        }
        
        if (!gameState["successTime"]) {
          getDataService().updateGameState({ successTime: (new Date()).getTime() });
        }
      }

      if (button_sequence_fit >= button_tar_seq.length && element.name && element.name.indexOf("Exit_Door") >= 0) {
        const tarRot = -90.0 / 180.0 * Math.PI;
        if (element.rotation.y != tarRot) {
          element.rotation.y = tarRot;
          recalculate = true;
        }
      }

      if (element.name && (element.name.indexOf("Key") >= 0 || element.name.indexOf("Puzzle") >= 0) && element.name.indexOf("Movable") <= 0) {
        if (gameState[element.name] !== undefined) {
          let tableSpawner = getRenderService().scene.getObjectByName("Table_Spawner");
          element.position.copy(tableSpawner.position);
          element.position.x += Math.random() - 0.5;
          element.rotation.set(0, 0, 0);
          if (element.name.indexOf("Puzzle") >= 0) {
            element.rotation.y = Math.random() - 0.5;
          }
          element.name = element.name + "_Movable";
        }
      }
      if (element.name && (element.name.indexOf("Key") >= 0 || element.name.indexOf("Puzzle") >= 0) && element.name.indexOf("Movable") > 0) {
        if (gameState[element.name] !== undefined) {
          let tableSpawner = getRenderService().scene.getObjectByName("Table_Spawner");
          element.position.x = gameState[element.name].pos.x;
          element.position.y = gameState[element.name].pos.y;
          element.position.z = gameState[element.name].pos.z;
          element.rotation.x = gameState[element.name].rot.x;
          element.rotation.y = gameState[element.name].rot.y;
          element.rotation.z = gameState[element.name].rot.z;
        }
      }
    });

    if (recalculate) {
      getRenderService().worldOctree.clear();
      getRenderService().worldOctree.fromGraphNode(getRenderService().scene);
    }
  }

  tickScene(tick, rs) {

  }

  render() {
    let gameState = getDataService().getGameState();
    if(gameState.successTime && !this.state.finalDialogShown){
      window.setTimeout(()=>{document.exitPointerLock();
        this.setState({finalDialog: true, finalDialogShown: true});}, 5000);
      
    }

    const self = this;
    let selection = undefined;
    if (this.state.selectedObject) {
      selection = this.state.selectedObject.object.name;
      console.log(selection);
    }

    let dialogClose = () => {
      document.body.requestPointerLock();
      getRenderService().enableKeyboardControls = true;
      self.setState({ combinationlock: undefined, book: undefined, inspect: undefined, finalDialog: undefined });
    };

    let combinationlock = "";
    if (this.state.combinationlock) {
      combinationlock = <CombinationLock selectedObject={selection} onResult={dialogClose} onClose={dialogClose} />;
    }

    let book = "";
    if (this.state.book) {
      book = <BookDialog selectedObject={selection} onResult={dialogClose} onClose={dialogClose} />;
    }
    let cursor = [];
    if (this.state.pointerLock) {
      let translation = getTranslationService().translate(selection);

      let interaction = "";
      let currentInteraction = self.interactions[selection]
      if (currentInteraction) {
        interaction = [<br />,
        <div className={"tag is-link"}>
          {getTranslationService().translate(currentInteraction)}
        </div>]
      }

      cursor = [
        <div className={"cursor " + (this.state.selectedObject ? " cursor-hover " : "")}></div>];
      //if(getTranslationService().translate(selection)){
      cursor.push(<div key="cursor" style={{ width: "100%" }} className={"selection " + (selection && translation ? "selection-hover" : "")}>
        <div className={"tag is-dark"}>
          {translation}
        </div>
        {interaction}
      </div>);

      cursor.push(<div className='bottom-right-hint has-text-white'>
        <table >
          <tbody>
          <tr>
            <td className='has-text-right'><span className='keyboard-key'>Esc</span></td>
            <td className='pl-2'>{getTranslationService().translate("Menü")}</td>
          </tr>
          <tr>
            <td className='has-text-right pt-2'><span className='keyboard-key'>W</span><span className='keyboard-key'>A</span><span className='keyboard-key'>S</span><span className='keyboard-key'>D</span></td>
            <td className='pl-2 pt-2'>{getTranslationService().translate("Bewegen")}</td>
          </tr>
          <tr>
            <td className='has-text-right  pt-2' ><span className='keyboard-key' style={{ width: "6rem" }}>{getTranslationService().translate("Leertaste")}</span></td>
            <td className='pl-2 pt-2'>{getTranslationService().translate("Springen")}</td>
          </tr>
          </tbody>
        </table>
      </div>);
      //}
    } else {
      const gamestate = this.state.gamestate;
      const hints = [];
      if(this.state.gamestate){
        // open first door
        hints.push({ hint: getTranslationService().translate("room1_hint_1_1"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room1_hint_1_2"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room1_hint_1_3"), solved: gamestate.lock1 });
        hints.push({ hint: getTranslationService().translate("room1_hint_1_4"), solved: gamestate.lock1 });
        // open first door
        hints.push({ hint: getTranslationService().translate("room1_hint_2_1"), solved: gamestate.lock2 });
        hints.push({ hint: getTranslationService().translate("room1_hint_2_2"), solved: gamestate.lock2 });
        hints.push({ hint: getTranslationService().translate("room1_hint_2_3"), solved: gamestate.lock2 });
        hints.push({ hint: getTranslationService().translate("room1_hint_2_4"), solved: gamestate.lock2 });
        // puzzle lock
        hints.push({ hint: getTranslationService().translate("room1_hint_3_1"), solved: this.state.button_seq_fit == 5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_3_2"), solved: this.state.button_seq_fit == 5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_3_3"), solved: this.state.button_seq_fit == 5 });
        
        // blue lock
        hints.push({ hint: getTranslationService().translate("room1_hint_4_1"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_4_2"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_4_3"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_4_4"), solved: gamestate.lock5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_4_5"), solved: gamestate.lock5 });
        // yellow lock
        const keys_found = gamestate.Key_1_1 && gamestate.Key_1_2 && gamestate.Key_2_1 && gamestate.Key_2_2 && gamestate.Key_3_1 && gamestate.Key_3_2;
        hints.push({ hint: getTranslationService().translate("room1_hint_5_1"), solved: keys_found });
        hints.push({ hint: getTranslationService().translate("room1_hint_5_2"), solved: keys_found });
        hints.push({ hint: getTranslationService().translate("room1_hint_5_3"), solved: gamestate.lock3 });
        hints.push({ hint: getTranslationService().translate("room1_hint_5_4"), solved: gamestate.lock3 });
        hints.push({ hint: getTranslationService().translate("room1_hint_5_5"), solved: gamestate.lock3 });
        hints.push({ hint: getTranslationService().translate("room1_hint_5_6"), solved: gamestate.lock3 });
        // red lock
        hints.push({ hint: getTranslationService().translate("room1_hint_6_1"), solved: gamestate.lock4 });
        hints.push({ hint: getTranslationService().translate("room1_hint_6_2"), solved: gamestate.lock4 });
        hints.push({ hint: getTranslationService().translate("room1_hint_6_3"), solved: gamestate.lock4 });
        // combination lock
        hints.push({ hint: getTranslationService().translate("room1_hint_7_1"), solved: this.state.wall_seq_fit == 5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_7_2"), solved: this.state.wall_seq_fit == 5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_7_3"), solved: this.state.wall_seq_fit == 5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_7_4"), solved: this.state.wall_seq_fit == 5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_7_5"), solved: this.state.wall_seq_fit == 5 });
        hints.push({ hint: getTranslationService().translate("room1_hint_7_6"), solved: this.state.wall_seq_fit == 5 });
        
      }
      if (!(this.state.combinationlock || this.state.book || this.state.cameraFocus || this.state.inspect)) {
        cursor = <MenuOverlay onClose={dialogClose} gamestate={gamestate} hints={hints} onShowMessage={()=>{self.setState({finalDialog: true}); document.exitPointerLock();}} />;
      }
    }


    let tableView = "";
    if (this.state.cameraFocus) {
      tableView = <TableView onLeave={() => {
        this.setState({ cameraFocus: undefined });
        getRenderService().freeCamera = false;
        getRenderService().enablePointerLock = true;
        document.body.requestPointerLock();
        getRenderService().camera.rotation.x = 0;
        getRenderService().camera.rotation.z = 0;
      }} />;
    }

    let inspect = "";
    if (this.state.inspect) {
      inspect = <InspectThreeDialog selectedObject={this.state.inspect} onClose={dialogClose} />
    }

    getRenderService().enableKeyboardControls = !(this.state.cameraFocus || this.state.book || this.state.inspect || this.state.combinationlock);

    // 
    return (
      <div className="App">
        {combinationlock}
        {book}
        {tableView}
        {inspect}
        <PlayerRepresentation autoupdate={1} />
        <Timer/>
        <Notifications />
        {this.state.finalDialog?<FinalDialog  onClose={dialogClose} />:cursor}
      </div>
    );
  }

}

export default Room1;
