import './EnterPage.css';
import 'bulma/css/bulma.min.css';
import ReactHtmlParser from 'html-react-parser'
import { Component } from 'react';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { getTranslationService } from '../TranslationService';
import LanguageSelector from '../framework/LanguageSelector';

class EnterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {session_key:"", e_mail:""};
    this.redeem_code = this.redeem_code.bind(this);
    this.request_code = this.request_code.bind(this);
  }

  redeem_code(){
    const sessionKey = this.state.session_key;
    this.setState({wrong_code: false});
    const self = this;
    fetch('/join_session.php?key='+sessionKey)
    .then(response => response.json())
    .then(data => {
      if(data.status < 1){
        self.setState({wrong_code: true});
      }else if(data.status == 2){
        self.setState({session_status: getTranslationService().translate("enter_joining_server"), wrong_code: false});
        window.setTimeout(()=>{
          window.location.assign("http://"+window.location.hostname+"/lobby/?ip="+data.ip+":8081/socket/escaperoom");
        }, 1000);
      }else{
        self.setState({session_status: data.message, wrong_code: false});
        setTimeout(this.redeem_code, 1000);
      }
    });
  }
  request_code(){
    const self = this;
    self.setState({request_status: undefined});
    const email = this.state.e_mail;
    fetch('/send_mail.php?lang='+getTranslationService().getLang()+'&email='+email)
    .then(response => response.json())
    .then(data => {
      if(data.status == -2){
        self.setState({request_status: getTranslationService().translate("enter_error_no_codes_left")});
      }else if(data.status == -1){
        self.setState({request_status: getTranslationService().translate("enter_error_email_already_used")});
      }else{
        self.setState({request_status: getTranslationService().translate("enter_status_email_send")});
      }
    });
  }


  render() {
    const self = this;

    return <div style={{zIndex: 2}}>
      <LanguageSelector></LanguageSelector>
    <div className="columns is-multiline">
    </div>
    <nav className="navbar py-4">
      <div className="container is-fluid">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img className="image" src="/images/logo.svg" alt="" width="96px"/>
            </a>
        </div>
        <div className="navbar-menu">
          <div className="navbar-start"></div>
          <div className="navbar-item">

          </div>
        </div>
      </div>
    </nav>

    <section className="section">
      <div className="container">
        <div className="mb-6 pb-3 columns is-multiline">
          <div className="column is-12 is-6-desktop mx-auto has-text-centered" style={{"padding": "3rem"}}>
            <h2 className="mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">{getTranslationService().translate("enter_title")}</h2>
            <p className="subtitle has-text-grey mb-5">
              {getTranslationService().translate("enter_desc")}
            </p>
          </div>
        </div>
        <div className="columns is-multiline is-justify-content-space-between">
          <div className="column is-12 is-5-desktop">
            <div className="mb-6 is-flex">
              <div className="panel" style={{"width": "100%"}}>
                <p className="panel-heading">
                  {getTranslationService().translate("enter_code_use_title")}
                </p>
                
                <div className="panel-block" style={{"display": "block", "padding":"20px"}}>
                  
                  <div dangerouslySetInnerHTML={{__html: getTranslationService().translate("enter_code_use_desc")}}></div><br/>
                  
                  <div className="field">
                    <div className="control">
                      <input className="input" id="session_key" type="text" placeholder="Raumcode" value={self.state.session_key} onChange={(e)=>{self.setState({session_key: e.target.value})}}/>
                      <p id="wrong_code" style={{display: this.state.wrong_code?"block":"none"}} className="help is-danger">{getTranslationService().translate("enter_code_invalid")}</p>
                    </div>
                  </div>
                  
                  <div id="session_status" style={{display: this.state.session_status?"block":"none"}} className="notification is-info">
                  {this.state.session_status}
                  </div>
                  <div className="field">
                    <div className="control">
                      <button className="button is-link is-fullwidth" onClick={()=>{this.redeem_code();}}>{getTranslationService().translate("enter_code_redeem")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-12 is-5-desktop">
            <div className="mb-6 is-flex">
              <div className="panel is-primary" style={{width: "100%"}}>
                <p className="panel-heading">
                  {getTranslationService().translate("enter_code_get_title")}
                </p>
                
                <div className="panel-block" style={{display: "block", "padding":"20px"}}>
                  <p className="field">
                    {getTranslationService().translate("enter_code_get_desc")}
                  </p>
                  <div className="field">
                    <div className="control">
                      <input className="input" type="text" id="request_email" placeholder="E-Mail-Adresse" value={this.state.e_mail} onChange={(e)=>{self.setState({e_mail: e.target.value})}}/>
                    </div>
                  </div>
                  <div id="request_status" style={{display: this.state.request_status?"block":"none"}} className="notification is-info">
                    {this.state.request_status}
                  </div>
                  <div className="field">
                    <div className="control">
                      <button className="button is-primary is-fullwidth" onClick={()=>{this.request_code();}}>{getTranslationService().translate("enter_code_get_button")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer className="section">
      <div className="container">
        <div className="pb-5 is-flex is-flex-wrap-wrap is-justify-content-between is-align-items-center">
          <div className="mr-auto mb-2">
            <a className="is-inline-block" href="/">
              <img className="image" src="/images/logo.svg" alt="" width="96px"/></a>
          </div>
          <div>
            <ul className="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-center">
              <li className="mr-4"><a className="button is-white" href="/impressum.html">Impressum</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pt-5" style={{borderTop: "1px solid #dee2e6"}}></div>
      <div className="container">
        <div className="is-flex-tablet is-justify-content-between is-align-items-center">
          <p>All rights reserved © Nils Wenzler 2022</p>
          <div className="py-2 is-hidden-tablet"></div>
          <div className="ml-auto">
            <a className="mr-4 is-inline-block" href="#">
              <img src="/images/app-page/socials/facebook.svg" alt=""/></a>
            <a className="mr-4 is-inline-block" href="#">
              <img src="/images/app-page/socials/twitter.svg" alt=""/></a>
            <a className="mr-4 is-inline-block" href="#">
              <img src="/images/app-page/socials/github.svg" alt=""/></a>
            <a className="mr-4 is-inline-block" href="#">
              <img src="/images/app-page/socials/instagram.svg" alt=""/></a>
            <a className="is-inline-block" href="#">
              <img src="/images/app-page/socials/linkedin.svg" alt=""/></a>
          </div>
        </div>
      </div>
    </footer>
  </div>;
  }

}

export default EnterPage;
