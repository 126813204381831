import './LoginPage.css';
import 'bulma/css/bulma.min.css';
import { Component } from 'react';
import { getRenderService } from '../framework/ThreeRenderService'
import CombinationLock from '../framework/CombinationLock';
import { getDataService } from '../framework/DataService';
import BookDialog from '../framework/BookDialog';
import Notifications from '../framework/Notifications';
import Timer from '../framework/Timer';
import * as THREE from 'three';
import TableView from '../framework/TableView';
import PlayerRepresentation from '../framework/PlayerRepresentation';
import { getTranslationService } from '../TranslationService';
import InspectThreeDialog from '../framework/InspectThreeDialog';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Navigate } from 'react-router-dom';
import LanguageSelector from '../framework/LanguageSelector';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    getRenderService();
    getRenderService().freeCamera = true;
    getRenderService().enablePointerLock = false;
    getRenderService().enableKeyboardControls = false;
    this.mounted = false;

    this.state = { users: {}, connected: false, loading: false, session_key:""};
    
    this.updateState = this.updateState.bind(this);
    this.updateLoginStatus = this.updateLoginStatus.bind(this);
    this.joinSteamSession = this.joinSteamSession.bind(this);
    this.openSteamLogin = this.openSteamLogin.bind(this);
    this.redeem_code = this.redeem_code.bind(this);
  }

  redeem_code(){
    const sessionKey = this.state.session_key;
    this.setState({wrong_code: false});
    const self = this;
    fetch('/join_session.php?key='+sessionKey)
    .then(response => response.json())
    .then(data => {
      if(data.status < 1){
        self.setState({wrong_code: true});
      }else if(data.status == 2){
        self.setState({session_status: getTranslationService().translate("enter_joining_server"), wrong_code: false});
        window.setTimeout(()=>{
          window.location.assign("http://"+window.location.hostname+"/lobby/?ip="+data.ip+":8081/socket/escaperoom");
        }, 1000);
      }else{
        self.setState({session_status: data.message, wrong_code: false});
        setTimeout(this.redeem_code, 1000);
      }
    });
  }

  updateLoginStatus() {
    const self = this;
    fetch('/steamauth/steam.php?status=1')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if(self.state.logged_in != data.logged_in){
          self.setState({loading: false});
        }
        self.setState(data);
      });
  }

  joinSteamSession(){
    const self = this;
    fetch('/steam_session.php')
    .then(response => response.json())
    .then(data => {
      if(data.status < 1){
        self.setState({wrong_code: true, error_message: data.message});
      }else if(data.status == 2){
        self.setState({session_status: getTranslationService().translate("enter_joining_server"), wrong_code: false});
        window.setTimeout(()=>{
          window.location.assign("http://"+window.location.hostname+"/lobby/?ip="+data.ip+":8081/socket/escaperoom");
        }, 1000);
      }else{
        self.setState({session_status: data.message, wrong_code: false});
        setTimeout(this.joinSteamSession, 1000);
      }
    });
  }

  componentDidMount() {
    getDataService().addCallback("sceneupdate", this.updateState);
    getDataService().addCallback("gamestate", this.updateState);
    getDataService().addCallback("userstate", this.updateState);

    if (this.mounted) {
      return;
    }
    setInterval(this.updateState, 1000);

    if (this.updateLoginStatusHandle) {
      clearInterval(this.updateLoginStatusHandle);
    }
    this.updateLoginStatusHandle = setInterval(this.updateLoginStatus, 5000);



    this.mounted = true;
    const self = this;


    getRenderService().camera.position.z = 2.3;
    getRenderService().camera.position.x = 2.0;
    getRenderService().camera.position.y = 1.6;
    getRenderService().camera.rotation.y = 0;

    const loader = new GLTFLoader();
    this.dungeonScene = undefined;
    this.spaceScene = undefined;

    loader.load('/temple_scene_lobby.glb', (gltf) => {

      this.spaceScene = gltf.scene;
      getRenderService().scene.add(gltf.scene);

      gltf.scene.traverse(child => {
        if (child.type == "PointLight") {
          child.intensity /= 400;
        }
        if (child.name === "Chest_Open") {
          child.visible = false;
        }
        if (child.name === "Scroll_Chest") {
          child.visible = false;
        }
        if (child.isMesh) {
          if (child.material) {
            child.material.roughness = 1;
            child.material.metalness = 0;
          }
        }

      });

      loader.load('/test_dungeon_scene.glb', (gltf) => {

        this.dungeonScene = gltf.scene;
        getRenderService().scene.add(gltf.scene);

        gltf.scene.traverse(child => {
          if (child.type == "PointLight") {
            child.intensity /= 400;
          }
          if (child.name === "Chest_Open") {
            child.visible = false;
          }
          if (child.name === "Scroll_Chest") {
            child.visible = false;
          }
          if (child.isMesh) {
            if (child.material) {
              child.material.roughness = 1;
              child.material.metalness = 0;
            }
          }

        });

        getRenderService().worldOctree.fromGraphNode(gltf.scene);
        getRenderService().animate();

        getRenderService().triggerCallback("sceneupdate", getRenderService().scene);
        let loginTargetPos = new THREE.Vector3(0, 0, 0);
        getRenderService().addCallback("scenetick", (timediff, rs) => {
          var camPos = rs.camera.position.clone();       // Holds current camera position
          const numberOfPlayers = Object.values(this.state.users).filter(u => !u._data.offline).length;
          loginTargetPos.x = rs.camera.position.x;
          loginTargetPos.y = rs.camera.position.y;
          loginTargetPos.z = rs.camera.position.z;


          // Interpolate camPos toward targetPos
          rs.camera.position.lerp(loginTargetPos, 3 * timediff);
        });

        getDataService().updateGameState({ room: "Dungeon" })
      });

    });

  }

  updateState() {
    let state = getDataService().getGameState();
    if (state.page && window.location.pathname !== state.page) {
      window.location.href = state.page;
    }

    if (this.dungeonScene && this.spaceScene) {
      if (state.room == "Dungeon") {
        this.dungeonScene.visible = true;
        this.spaceScene.visible = false;
      } else {
        this.dungeonScene.visible = false;
        this.spaceScene.visible = true;
      }
    }

    if (!this.state.connected) {
      this.setState({ connected: true });
      console.log("login registered connection.")
    }

    let users = getDataService().getUsers();

    this.setState({ users: users });
    let name = "";
    let me = getDataService().getMe();
    if (me && me._data && me._data.state && me._data.state.name) {
      name = me._data.state.name;
    }
    if (me && me._data && me._data.state && !this.state.setPosition) {
      const playerNumber = getDataService().getPlayerNumber(me._data.id);
      let statePos = { x: playerNumber * 0.95, y: 1.6, z: -0.5 };
      me.updateState({ pos: statePos, rot: { x: 0, y: Math.PI, z: 0 } });
      this.setState({ setPosition: 1 });
    }
    this.setState({ name: name });
  }

  openSteamLogin(){
    const self = this;
    this.setState({ loading: true });
    window.steamLoginWindow = window.open("/steamauth/steam.php?login", "_blank");
    var timer = setInterval(function() { 
      if(window.steamLoginWindow.closed) {
          clearInterval(timer);
          self.setState({ loading: false });
      }
    }, 1000);
  }

  render() {
    const self = this;

    let loginContent = <div className="loader is-loading"></div>;
    if (!this.state.logged_in && !this.state.loading) {
      loginContent = [<h1 className="title">{getTranslationService().translate("enter_escape_room")}</h1>,
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }}>
          <p className="subtitle is-5">{getTranslationService().translate("with_steam")}</p>
          <a href="#" onClick={() => { this.openSteamLogin() }} style={{ display: "block", textAlign: "center" }}><img src="/black_steam_sign_in.png" /></a>
        </div>
        <div className="divider is-vertical">{getTranslationService().translate("or")}</div>
        <div style={{ flex: "1" }}>
          <p className="subtitle is-5">{getTranslationService().translate("with_session_key")}</p>
          <div className="field">
            <div className="control">
              <button className="button is-link is-fullwidth" onClick={()=>{self.setState({redeem_code: 1})}}>{getTranslationService().translate("enter_code_redeem")}</button>
            </div>
          </div>
        </div>
      </div>];
    }
    if(this.state.redeem_code) {
      loginContent = [<h1 className="title is-3">{getTranslationService().translate("login_welcome")}</h1>,
        <div>
          <div dangerouslySetInnerHTML={{__html: getTranslationService().translate("login_intro")}}></div>
          <br/>
          <p><b>{getTranslationService().translate("login_redeem_once")}</b></p>
          <br/>
          <div style={{display: this.state.wrong_code?"block":"none"}} className="notification is-error">
            {this.state.error_message}
          </div>
          <div id="session_status" style={{display: this.state.session_status?"block":"none"}} className="notification is-info">
          {this.state.session_status}
          </div>
          <div className="field">
            <div className="control">
              <input className="input" id="session_key" type="text" placeholder="" value={self.state.session_key} onChange={(e)=>{self.setState({session_key: e.target.value})}}/>
              <p id="wrong_code" style={{display: this.state.wrong_code?"block":"none"}} className="help is-danger">{getTranslationService().translate("enter_code_invalid")}</p>
            </div>
          </div>
          
          <div id="session_status" style={{display: this.state.session_status?"block":"none"}} className="notification is-info">
          {this.state.session_status}
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-link is-fullwidth" onClick={()=>{this.redeem_code();}}>{getTranslationService().translate("enter_code_redeem")}</button>
              <button className="button is-fullwidth mt-4" onClick={()=>{self.setState({redeem_code: undefined})}}>{getTranslationService().translate("back")}</button>
            </div>
          </div>
          
        </div>];
    }
    if (this.state.logged_in) {
      let logoutButton = <a href="/steamauth/steam.php?logout" onClick={() => { this.setState({ loading: true }) }} target="_blank" style={{ display: "block"}}>
        <button className='button is-fullwidth'>Log out</button>
      </a>;
      if (this.state.owns_game) {
        loginContent = [<h1 className="title is-3">{getTranslationService().translate("login_welcome")} {this.state.steam_persona}</h1>,
        <div>
          <div dangerouslySetInnerHTML={{__html: getTranslationService().translate("login_intro")}}></div>
          <br/>
          <div style={{display: this.state.wrong_code?"block":"none"}} className="notification is-error">
            {this.state.error_message}
          </div>
          <div id="session_status" style={{display: this.state.session_status?"block":"none"}} className="notification is-info">
          {this.state.session_status}
          </div>
          <button className='button is-primary is-fullwidth mb-2' onClick={this.joinSteamSession}>Start Escape Room Session</button>
          {logoutButton}
        </div>
        ];
      } else {
        loginContent = [<h1 className="title is-3">{getTranslationService().translate("login_welcome")} {this.state.steam_persona}</h1>,
        <div>
          <div dangerouslySetInnerHTML={{__html: getTranslationService().translate("login_game_not_owned")}}></div>
          <br/>
          {logoutButton}
        </div>
        ];
      }
    }

    return (
      <div key="main_container" className='mainContainer'>
        <LanguageSelector></LanguageSelector>
        <div style={{ flexGrow: 1 }}></div>
        <div className='login-center'>
        <div className='logo-image'></div>
        
        <div style={{ flexGrow: 1, minHeight: "2rem", flexShrink: 0}}></div>
          <section className="section card" style={{paddingTop: "1.5rem",display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
            {loginContent}
          </section>
          
          <div style={{ flexGrow: 1, minHeight: "2rem", flexShrink: 0 }}></div>
        </div>

        <div style={{ flexGrow: 1 }}></div>
      </div>
    );
  }

}


export default LoginPage;
