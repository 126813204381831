import './LanguageSelector.css';
import 'bulma/css/bulma.min.css';
import { Component, createRef } from 'react';
import { getDataService } from './DataService';
import { getTranslationService } from '../TranslationService';


class LanguageSelector extends Component {
  constructor(props) {
    super(props);

    this.timerRef = createRef();  
    this.state = { notifications: [] };

    //this.updateNotifications = this.updateNotifications.bind(this);

    getDataService().addCallback("gameevent", (ev)=>{
    });

  }

  componentDidMount(){
    
    const self = this;
  }

  
  render() {

    let link = "";
    const self = this;

    if(this.state.success){
      link = <div><a href="#" onClick={()=>{self.props.onShowMessage();}} style={{textAlign: "center", width: "100%"}}>Show message</a></div>
    }

    return (
      <div className={this.props.inline?"language-selector-container-inline":"language-selector-container-fixed"}>
        <div className={'card language-selector-card '+(this.state.success?"has-background-success-light":"")}>
          <div className="card-content">
          <div class="dropdown is-hoverable is-right">
  <div class="dropdown-trigger">
    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu2">
      <img className="flag-image" src={"/images/"+getTranslationService().getLang()+".svg"} alt=""/>
      <span class="icon is-small">
        <i class="fas fa-angle-down" aria-hidden="true"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu" id="dropdown-menu2" role="menu">
    <div class="dropdown-content">
      <div class="dropdown-item language-selector-item" onClick={()=>{getTranslationService().setLang("de")}}>
        <img className="flag-image" src="/images/de.svg" alt=""/> {getTranslationService().translate("german")}
      </div>
      <div class="dropdown-item language-selector-item" onClick={()=>{getTranslationService().setLang("en")}}>
        <img className="flag-image" src="/images/en.svg" alt=""/> {getTranslationService().translate("english")}
      </div>
    </div>
  </div>
</div>
          </div>
        </div>
      </div>
    );
  }

}

export default LanguageSelector;
